.checkboxGroup {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.label {
  flex: 1;
  text-align: center;
}

.checkboxGroupItems {
  flex-basis: 370px;
  display: flex;
}

